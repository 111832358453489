import { useNavigation } from '@react-navigation/native'
import { Center, Flex, HStack, Text, theme, VStack } from 'native-base'
import { useCallback, useEffect, useState } from 'react'
import { Pressable } from 'react-native'
import SEARCH_STATUS from '../../../../domain/enums/ExploreSearch'
import {
  useDesktopBreakpoint,
  useMobileBreakpoint,
} from '../../../../domain/services/useBreakpoint'
import CategoryFilter from '../../components/explore/CategoryFilter'
import SearchComponent from '../../components/explore/SearchComponent'
import TimeFilter from '../../components/explore/TimeFilter'
import SearchBar from '../../components/_shared/form/SearchBar'
import FixedContainer from '../../components/_shared/layout/FixedContainer'
import { CategoryFilters, TimeFilters } from '../../constants/filterConstants'
import secureScreen from '../../hooks/authentication/secureScreen'
import useAnalytics from '../../hooks/useAnalytics'
import useGetOasisColors from '../../hooks/useGetOasisColors'
import DefaultLayout from '../../layouts/DefaultLayout'

const SearchScreen = ({ route }) => {
  const routeParsedTimeFilters =
    route.params?.timeFilters != undefined
      ? [TimeFilters[route.params?.timeFilters]]
      : []
  const [timeFilters, setTimeFilters] = useState(routeParsedTimeFilters)

  const routeParsedCategoryFilters =
    route.params?.categoryFilters != undefined
      ? [CategoryFilters(false)[route.params?.categoryFilters].name]
      : []
  const [categoryFilters, setCategoryFilters] = useState(
    routeParsedCategoryFilters
  )

  const isMobile = useMobileBreakpoint()
  const isDesktop = useDesktopBreakpoint()
  const navigation = useNavigation()
  const { tabColor } = useGetOasisColors()
  const analytics = useAnalytics()

  const [searchValue, setSearchValue] = useState(
    route.params?.searchValue || ''
  )
  const [searchStatus, setSearchStatus] = useState(
    route.params?.searchValue ? SEARCH_STATUS.finished : SEARCH_STATUS.started
  )

  useEffect(() => {
    analytics.track('Search Reached')
  }, [])

  const handleFocus = useCallback((isFocused) => {
    if (isFocused) setSearchStatus(SEARCH_STATUS.started)
  }, [])

  const onRemoveAllFilters = useCallback(() => {
    if (!searchValue && !timeFilters.length && !categoryFilters.length)
      setSearchStatus(SEARCH_STATUS.started)
  }, [searchValue, timeFilters, categoryFilters])

  useEffect(() => {
    if (timeFilters.length > 0 || categoryFilters.length > 0) {
      setSearchStatus(SEARCH_STATUS.finished)
    } else if (searchValue == '' && searchStatus == SEARCH_STATUS.finished)
      setSearchStatus(SEARCH_STATUS.started)
  }, [timeFilters, categoryFilters])

  const handleSubmit = useCallback(
    (newSearch) => {
      if (newSearch || searchValue) {
        setSearchValue(newSearch ?? searchValue)
        analytics.track('Searched', {
          query: newSearch ?? searchValue,
          timeFilters: timeFilters.length === 0 ? undefined : timeFilters,
          categoryFilters:
            categoryFilters.length === 0 ? undefined : categoryFilters,
          screen: 'Search',
        })
        setSearchStatus(SEARCH_STATUS.finished)
      }
    },
    [searchValue]
  )

  const onModifyText = useCallback((newSearch) => {
    setSearchValue(newSearch)
    if (newSearch) {
      setSearchStatus(SEARCH_STATUS.typing)
    } else {
      setSearchStatus(SEARCH_STATUS.started)
    }
  }, [])

  const clearFilters = useCallback(() => {
    setCategoryFilters([])
    setTimeFilters([])
  }, [])

  const handleCancel = useCallback(() => {
    setSearchValue('')
    clearFilters()
  }, [])

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false)
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false)

  return (
    <>
      {!isDesktop ? (
        <DefaultLayout activeLabel="Explore" scrollView={false}>
          <VStack bg={tabColor} h="100%" safeAreaTop>
            <Flex
              m="5"
              bg="white"
              borderRadius={4}
              borderColor="muted.300"
              borderWidth="1px">
              <SearchBar
                value={searchValue}
                setValue={onModifyText}
                handleSubmit={handleSubmit}
                placeholder="Live groups, topics, activities"
                handleFocus={handleFocus}
                handleCancel={handleCancel}
                autoFocused={
                  !route.params?.searchValue &&
                  routeParsedTimeFilters.length === 0 &&
                  routeParsedCategoryFilters.length === 0
                }
                showCancel
              />
            </Flex>
            <HStack justifyContent="space-between" mb="24px" mx={5}>
              <HStack>
                <TimeFilter
                  setTimeFilters={setTimeFilters}
                  timeFilters={timeFilters}
                  onRemoveAllFilters={onRemoveAllFilters}
                  setIsFilterModalOpen={setIsFilterModalOpen}
                  key="timeFilter"
                />
                <CategoryFilter
                  onRemoveAllFilters={onRemoveAllFilters}
                  setCategoryFilters={setCategoryFilters}
                  categoryFilters={categoryFilters}
                  setIsFilterModalOpen={setIsFilterModalOpen}
                  key="categoryFilter"
                />
              </HStack>
              <Center>
                {(timeFilters.length > 0 || categoryFilters.length > 0) && (
                  <Pressable onPress={clearFilters}>
                    <Text color="white" fontSize={12}>
                      Clear all
                    </Text>
                  </Pressable>
                )}
              </Center>
            </HStack>
            <Flex flex={1} bg="white" borderTopRadius="2xl">
              <SearchComponent
                searchValue={searchValue}
                handleSubmit={handleSubmit}
                setSearchStatus={setSearchStatus}
                searchStatus={searchStatus}
                timeFilters={timeFilters}
                categoryFilters={categoryFilters}
              />
            </Flex>
          </VStack>
        </DefaultLayout>
      ) : (
        <VStack h="100%" bg="muted.50">
          <Flex bg={tabColor} shadow="3">
            <FixedContainer size="lg">
              <Flex m="5">
                <SearchBar
                  value={searchValue}
                  setValue={onModifyText}
                  handleSubmit={handleSubmit}
                  placeholder="Live groups, topics, activities"
                  handleFocus={handleFocus}
                  handleCancel={handleCancel}
                  autoFocused={!route.params?.searchValue}
                  showCancel
                  isBeingFiltered={isFilterModalOpen}
                />
              </Flex>
              <HStack justifyContent="space-between" mb="24px" mx={5}>
                <HStack>
                  <TimeFilter
                    setTimeFilters={setTimeFilters}
                    timeFilters={timeFilters}
                    onRemoveAllFilters={onRemoveAllFilters}
                    setIsFilterModalOpen={setIsFilterModalOpen}
                    key="timeFilter"
                  />
                  <CategoryFilter
                    onRemoveAllFilters={onRemoveAllFilters}
                    setCategoryFilters={setCategoryFilters}
                    categoryFilters={categoryFilters}
                    setIsFilterModalOpen={setIsFilterModalOpen}
                    key="categoryFilter"
                  />
                </HStack>
                <Center>
                  {(timeFilters.length > 0 || categoryFilters.length > 0) && (
                    <Pressable onPress={clearFilters}>
                      <Text color="white" fontSize={12}>
                        Clear all
                      </Text>
                    </Pressable>
                  )}
                </Center>
              </HStack>
            </FixedContainer>
          </Flex>
          <Flex flex="1" w={theme.breakpoints.lg} py="10" mx="auto">
            <Flex
              h="100%"
              bg="white"
              borderRadius="2xl"
              borderColor="grey.200"
              borderWidth={1}
              mx="5">
              <SearchComponent
                searchValue={searchValue}
                handleSubmit={handleSubmit}
                setSearchStatus={setSearchStatus}
                searchStatus={searchStatus}
                timeFilters={timeFilters}
                categoryFilters={categoryFilters}
              />
            </Flex>
          </Flex>
        </VStack>
      )}
    </>
  )
}

export default secureScreen(SearchScreen)
