import { Box, HStack, Text, VStack } from 'native-base'
import { useEffect, useState } from 'react'
import { Platform } from 'react-native'
import { useRoomContext } from '../../../providers/Room30Provider'
import TechSupport from '../../room/TechSupport'
import FaIcon from '../../_shared/FaIcon'
import FormLabel from '../../_shared/form/FormLabel'
import MicrophonePicker from '../MicrophonePicker'
import RoomSettings from './RoomSettings'

const dateFormat = new Intl.DateTimeFormat([], {
  weekday: 'short',
  month: 'short',
  day: '2-digit',
})

const timeFormat = new Intl.DateTimeFormat([], {
  hour: 'numeric',
  minute: '2-digit',
  timeZoneName: 'short',
})

const InfoTab = () => {
  const { info } = useRoomContext()
  const [startsAt, setStartsAt] = useState('')
  const [settingsOpen, setSettingsOpen] = useState(false)

  useEffect(() => {
    const startsAtDate = new Date(info.startTime)
    const date = dateFormat.format(startsAtDate)
    const time = timeFormat.format(startsAtDate)

    setStartsAt(`${date} • ${time}`)
  }, [info.startTime])

  const iOS = Platform.OS === 'ios'

  return (
    <VStack p={4}>
      <HStack justifyContent="space-between">
        <VStack>
          <Text color="black" fontWeight={700}>
            {info.title}
          </Text>
          <Text mt={2} mb={4} color="black">
            {startsAt}
          </Text>
          {info.description ? (
            <VStack mb={iOS ? 4 : 10}>
              <Text mb={2} color="text.500" fontWeight={500}>
                Description
              </Text>
              <Text color="black">{info.description}</Text>
            </VStack>
          ) : (
            <></>
          )}
        </VStack>
        {!iOS ? (
          <FaIcon
            onPress={() => setSettingsOpen(true)}
            name="ellipsis-vertical"
            size={20}
          />
        ) : (
          <></>
        )}
      </HStack>
      {Platform.OS === 'ios' ? (
        <Box>
          <FormLabel color="muted.500">Microphone</FormLabel>
          <MicrophonePicker />
        </Box>
      ) : (
        <></>
      )}
      <HStack
        flexDir="column"
        justifyContent="center"
        alignContent="center"
        alignItems="center">
        <Text mt={iOS ? 2 : 0} mb={2} fontSize="16px" color="muted.500">
          Experiencing technical issues?
        </Text>
        <TechSupport isMobile />
      </HStack>
      <RoomSettings
        settingsOpen={settingsOpen}
        closeSettings={() => setSettingsOpen(false)}
      />
    </VStack>
  )
}

export default InfoTab
