import Configuration from '../../../domain/services/ConfigurationService'

const useGroupEventProperties = (group, options = null) => {
  return {
    ID: group.activityId || group.id,
    Title: group.title || group.name,
    Type: group.type,
    Moderator: group.moderator.name || group.moderator.alias,
    Duration: group.duration,
    Start: new Date(group.startDate || group.startsAt),
    Description: group.description,
    'Detail Link': `${Configuration.CABANA_UI}/activity?activityId=${
      group.activityId || group.id
    }`,
    Tags: group.tags,
    ...options,
  }
}

export default useGroupEventProperties
