import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import {
  Heading,
  HStack,
  Pressable,
  ScrollView,
  Text,
  VStack,
} from 'native-base'
import { FlatList } from 'react-native'
import { CollectionCardProps } from '../../../../domain/models/interfaces/cardInterfaces'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import { NavigationParams } from '../../screens/NavigationScreen'
import CollectionCard from '../collection/CollectionCard'

interface CollectionsExploreProps {
  data: CollectionCardProps[]
}

const CollectionsExplore = ({ data }: CollectionsExploreProps) => {
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const isDesktop = useDesktopBreakpoint()
  const numColumns = isDesktop ? 3 : 2

  return (
    <VStack mt={isDesktop ? 4 : 0}>
      <HStack justifyContent="space-between" width="100%" alignSelf="center">
        <Heading size="sm" color="primary.900" mb={isDesktop ? 4 : 0}>
          Collections
        </Heading>
        <Pressable onPress={() => navigation.navigate('Collections')}>
          <Text color="primary.900">see all</Text>
        </Pressable>
      </HStack>
      {isDesktop ? (
        <FlatList
          columnWrapperStyle={{
            gap: 8,
          }}
          numColumns={numColumns}
          contentContainerStyle={{ flex: 1, justifyContent: 'center' }}
          data={data}
          renderItem={({ item }) => <CollectionCard collectionCard={item} />}
          keyExtractor={(item) => item.slug}
        />
      ) : (
        <ScrollView mt={4} horizontal showsHorizontalScrollIndicator={false}>
          {data.map((item) => (
            <CollectionCard mr={2} key={item.slug} collectionCard={item} />
          ))}
        </ScrollView>
      )}
    </VStack>
  )
}

export default CollectionsExplore
