import { Box, KeyboardAvoidingView, VStack } from 'native-base'
import { useState } from 'react'
import { Platform, SafeAreaView } from 'react-native'
import { AttendeeState30 } from '../../../../../domain/enums/AttendeeState'
import { CHAT, INFO, PEOPLE } from '../../../constants/roomConstants'
import { useRoomContext } from '../../../providers/Room30Provider'
import Tabs from '../../_shared/layout/Tabs'
import VideoFeed from '../VideoFeed'
import Chat from './Chat'
import InfoTab from './InfoTab'
import RoomFooter from './RoomFooter'
import Roster from './Roster'

const MobileRoom = () => {
  const [tabSelected, setTabSelected] = useState(PEOPLE)
  const { participants } = useRoomContext()

  const tabsData = {
    [PEOPLE]: {
      title: `People (${
        participants.filter(
          (participant) =>
            participant.screen === AttendeeState30.Room && participant.connected
        ).length
      })`,
      content: <Roster />,
    },
    [INFO]: {
      title: 'Info',
      content: <InfoTab />,
    },
    [CHAT]: {
      title: 'Chat',
      content: (
        <VStack flex={1}>
          <Chat />
        </VStack>
      ),
    },
  }

  return (
    <KeyboardAvoidingView
      background="white"
      behavior={Platform.OS === 'ios' ? 'padding' : null}
      style={{ flex: 1 }}>
      <SafeAreaView style={{ flex: 1 }}>
        <Box
          background="#262626"
          height="40px"
          justifyContent="center"
          alignItems="center"></Box>
        <Box background="transparent" height="252px" position="relative">
          <VideoFeed height="252px" borderRadius="0" />
          <Box
            position="absolute"
            width="192px"
            height="56px"
            alignSelf="center"
            bottom={2}
            marginLeft="auto"
            marginRight="auto"
            justifyContent="center"
            alignItems="center">
            <RoomFooter isMobile />
          </Box>
        </Box>
        <Box flex={1}>
          <Tabs
            h="100%"
            tabSelected={tabSelected}
            selectTab={setTabSelected}
            tabsData={tabsData}
          />
        </Box>
      </SafeAreaView>
    </KeyboardAvoidingView>
  )
}

export default MobileRoom
