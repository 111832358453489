import { useAuth0 } from '@auth0/auth0-react'
import Configuration from '../../../../domain/services/ConfigurationService'

const useAuthentication = () => {
  const {
    isLoading,
    user,
    logout,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    loginWithRedirect,
  } = useAuth0()

  const loginWithPassword = () => loginWithRedirect()
  const loginWithMagicLink = () =>
    loginWithRedirect({
      authorizationParams: { connection: 'email' },
    })
  const signup = () =>
    loginWithRedirect({
      authorizationParams: { screen_hint: 'signup' },
    })
  const getAccessToken = () => {
    const options = {
      authorizationParams: {
        audience: Configuration.AUTH0_AUDIENCE,
      },
    }

    return getAccessTokenSilently(options).catch((error) => {
      if (error.error === 'consent_required') {
        console.debug('Auth0 consent required')
        return getAccessTokenWithPopup(options)
      }
      return Promise.reject(error)
    })
  }

  const doLogout = () =>
    logout({
      logoutParams: {
        returnTo: Configuration.CABANA_UI,
      },
    })

  return {
    loginWithPassword,
    loginWithMagicLink,
    signup,
    user,
    getAccessToken,
    logout: doLogout,
    isLoading,
    reset: () => {},
  }
}

export default useAuthentication
