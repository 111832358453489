import useCustomerIo from './customerio/useCustomerIo'

const useAnalytics = () => {
  const customerIo = useCustomerIo()

  const track = (eventName: string, eventData: Record<string, any> = {}) => {
    customerIo.track(eventName, { ...eventData })
  }

  return {
    track,
  }
}

export default useAnalytics
