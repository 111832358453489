import { Box, Text } from 'native-base'
import { Linking } from 'react-native'
import useLinkHandler from '../../hooks/useLinkHandler'
import { theme } from '../../theme/theme'

const FRFScreen = () => {
  return (
    <Box p="32px">
      <Text pt="16px" fontSize="16px">
        {`Check out our `}{' '}
        <Text
          onPress={() =>
            useLinkHandler('https://cabana.upvoty.com/b/feedback/')
          }
          color={theme.colors.secondary[600]}
          underline>
          feature request and feedback
        </Text>
        {` page.

Still have questions? Please `}
        <Text
          onPress={() =>
            Linking.openURL('mailto:support@mycabana.health')
              .then((data) => console.debug(data))
              .catch((data) => console.debug(data))
          }
          color={theme.colors.secondary[600]}
          underline>
          contact us
        </Text>
        {`.`}
      </Text>
    </Box>
  )
}

export default FRFScreen
