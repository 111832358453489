import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Box, Button, Text } from 'native-base'
import { useEffect } from 'react'
import { useDesktopBreakpoint } from '../../../../../domain/services/useBreakpoint'
import useAnalytics from '../../../hooks/useAnalytics'
import {
  useCompass,
  useCompleteStepMutation,
} from '../../../queries/voyageQueries'
import { NavigationParams } from '../../../screens/NavigationScreen'
import { SkeletonLoader } from '../../_shared/SkeletonLoader'
import CompassContainer from './CompassContainer'
import CompassValues from './CompassValues'

const CompassResults = () => {
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const isDesktop = useDesktopBreakpoint()
  const { data, isLoading } = useCompass()
  const { mutation } = useCompleteStepMutation(true)
  const analytics = useAnalytics()

  useEffect(() => {
    analytics.track('Values Exercise Completed', {
      North: data?.north,
      East: data?.east,
      South: data?.south,
      West: data?.west,
    })
  }, [])

  return (
    <CompassContainer>
      {data && (
        <Box display="flex" flexDirection={isDesktop ? 'row' : 'column'}>
          <Box width={!isDesktop ? '100%' : '60%'}>
            <Text mb={4} color="primary.900" fontSize="24px" fontWeight={500}>
              All done!
            </Text>
            <Text color="primary.900">
              These are values you can fall back on in a difficult situation,
              perhaps even without thinking, and rely on them to guide you to
              handle situations in harmony with what is most important to you.
            </Text>
            <Text mt={6} color="primary.900">
              Before you go, review your compass. Feel free to revisit it here
              any time.
            </Text>
            {isLoading && (
              <SkeletonLoader mb={6} size={{ width: '100%', height: '88px' }} />
            )}
            {!isDesktop && <CompassValues data={data} />}
            <Text color="primary.900">
              You feel best when your behavior and life choices align with your
              values. They are who you are at your core before the dust of
              life's pressures accumulates and hides that bright and shining
              compass.
            </Text>
            <Button
              alignSelf="center"
              width={!isDesktop ? '100%' : '342px'}
              mt={6}
              onPress={() => {
                mutation.mutate('recdlNqiz08fUAc9BCOMPASS')
                navigation.navigate('LandingVoyage')
              }}>
              <Text color="white" fontSize="16px" fontWeight={500}>
                Finish
              </Text>
            </Button>
          </Box>
          {isDesktop && (
            <Box width={!isDesktop ? '100%' : '40%'}>
              <CompassValues data={data} />
            </Box>
          )}
        </Box>
      )}
    </CompassContainer>
  )
}

export default CompassResults
