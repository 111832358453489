import {
  ActivityRegistrationState,
  ActivityType,
} from '../../../domain/models/interfaces/activityInterfaces'

const getActivityModalDetails = (
  activityType,
  navigate,
  addEvent,
  handleParentClose
) => {
  switch (activityType) {
    case ActivityType.GROUP_LISTEN:
    case ActivityType.GROUP_OTHER:
    case ActivityType.GROUP_SERIES:
    case ActivityType.GROUP_SKILL:
    case ActivityType.GROUP_SUPPORT:
      return {
        [ActivityRegistrationState.AVAILABLE]: {
          title: 'You saved your spot!',
          description:
            'You’ll get a confirmation email and reminder before your live group.',
          buttonText: 'Add to calendar',
          image: require('../assets/images/live-groups/modals/attending.png'),
          action: () => {
            addEvent()
          },
        },
        [ActivityRegistrationState.ATTENDING]: {
          title: 'Your spot was released',
          description:
            'We understand, life gets busy. Let’s find something else that works for you.',
          buttonText: 'Keep exploring',
          image: require('../assets/images/live-groups/modals/not-attending.png'),
          action: () => {
            navigate('Explore')
            handleParentClose()
          },
        },
      }
    default:
      return {}
  }
}

export default getActivityModalDetails
