import { HStack, Select, VStack } from 'native-base'
import { useEffect, useState } from 'react'

import { useCamerasContext } from '../../providers/CameraProvider'
import FaIcon from '../_shared/FaIcon'

const CameraPicker = () => {
  const {
    cameras,
    refresh,
    selectCamera,
    selectedCamera,
    requestCameraPermission,
  } = useCamerasContext()

  const [selectedValue, setSelectedValue] = useState(selectedCamera?.name || '')

  useEffect(() => {
    requestCameraPermission()
  }, [])

  useEffect(() => {
    setSelectedValue(selectedCamera?.name || '')
  }, [selectedCamera])

  const handleSelect = (selectedName: string) => {
    const selectedCamera = cameras.find(
      (camera) => camera.name === selectedName
    )
    if (selectedCamera) selectCamera(selectedCamera)
  }

  const doRefresh = () => {
    refresh()
  }

  const picker = (
    <VStack space={4}>
      <HStack alignItems="center" justifyContent="space-between" space={4}>
        <Select
          flex={1}
          selectedValue={selectedValue}
          accessibilityLabel="Select Camera"
          placeholder="Select Camera"
          size="lg"
          onValueChange={handleSelect}>
          {cameras.map((cam) => (
            <Select.Item key={cam.name} label={cam.name} value={cam.name} />
          ))}
        </Select>
        <FaIcon name="rotate" onPress={() => doRefresh()} />
      </HStack>
    </VStack>
  )

  return picker
}

export default CameraPicker
