import { Button, Text } from 'native-base'
import { useRoomContext } from '../../../providers/Room30Provider'
import BottomSheet from '../../_shared/notification/BottomSheet'

const EndGroupModal = ({ open, setOpen }) => {
  const { stop } = useRoomContext()

  return (
    <BottomSheet
      isOpen={open}
      width="300px"
      title="End live group"
      closeButtonText="Cancel"
      body={
        <>
          <Text mb="5">Are you sure you want to end this live group?</Text>
          <Text color="tertiary.600">
            Warning: you will not be able to restart the group once it's ended.
          </Text>
        </>
      }
      actions={
        <Button
          colorScheme="tertiary"
          onPress={() => {
            stop()
          }}>
          End group
        </Button>
      }
      handleClose={() => setOpen(false)}
    />
  )
}

export default EndGroupModal
