import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import {
  Box,
  Heading,
  HStack,
  Image,
  ScrollView,
  Skeleton,
  Text,
  useBreakpointValue,
  VStack,
} from 'native-base'
import { useEffect } from 'react'
import Markdown from 'react-native-marked'
import { Group } from '../../../../domain/models/interfaces/activityInterfaces'
import { useMobileBreakpoint } from '../../../../domain/services/useBreakpoint'
import mdStylesDesktop from '../../constants/mdStylesDesktop'
import mdStylesMobile from '../../constants/mdStylesMobile'
import useAnalytics from '../../hooks/useAnalytics'
import { useCollection } from '../../queries/collectionsQueries'
import { NavigationParams } from '../../screens/NavigationScreen'
import PathCard from '../voyages/path/PathCard'
import ActivityCard from '../_shared/card/ActivityCard'
import GroupCard from '../_shared/card/GroupCard'
import FaIcon from '../_shared/FaIcon'

const Collection = ({ slug }: { slug: string }) => {
  const { data: collection, isLoading } = useCollection(slug)
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const isMobile = useMobileBreakpoint()
  const analytics = useAnalytics()

  const cardWidth = useBreakpointValue({
    base: undefined,
    md: 300,
  })

  const cardDirection = useBreakpointValue({
    base: 'column',
    md: 'row',
  })

  const containerSpace = isMobile ? 0 : 10
  const sectionSpace = isMobile ? 4 : 0

  const gotoGroup = (group: Group) => {
    navigation.navigate('Activity', { activityId: group.id })
  }

  useEffect(() => {
    if (collection) {
      analytics.track('Collection Viewed', { Title: collection.title })
    }
  }, [collection])

  return (
    <ScrollView
      borderRadius={isMobile ? 0 : '20px'}
      borderColor="grey.200"
      borderWidth={1}
      my={containerSpace}
      width={'full'}
      padding={containerSpace}
      backgroundColor={'white'}>
      {/* banner image */}
      <Skeleton height={80} isLoaded={!isLoading}>
        {collection && (
          <Image
            borderRadius={containerSpace}
            width={'full'}
            height={80}
            source={{ uri: collection.bannerImage }}
            alt={collection.title}
          />
        )}
      </Skeleton>

      <VStack space={4} px={sectionSpace} my={4}>
        <HStack>
          {/* collection tag */}
          <Skeleton
            rounded={'full'}
            height={6}
            width={40}
            isLoaded={!isLoading}>
            {collection && (
              <HStack
                backgroundColor={'gray.200'}
                paddingX={3}
                paddingY={1}
                borderRadius={12}
                space={1}
                alignItems={'center'}>
                <FaIcon color={'text.900'} name="list" />
                <Text color={'text.900'}>COLLECTION</Text>
              </HStack>
            )}
          </Skeleton>
        </HStack>
        {/* author */}
        <HStack alignItems={'center'} space={2}>
          <Skeleton rounded={'full'} height={8} width={8} isLoaded={!isLoading}>
            {collection && (
              <Image
                alt={collection.title}
                source={{ uri: collection.author.photo }}
                size={8}
                rounded={'full'}
              />
            )}
          </Skeleton>
          <Skeleton.Text lines={1} width={20} height={4} isLoaded={!isLoading}>
            {collection && (
              <Text color={'text.900'} fontSize={16}>
                {collection.author.name}
              </Text>
            )}
          </Skeleton.Text>
        </HStack>
        <VStack pb={4} space={2}>
          {/** title */}
          <Skeleton.Text lines={1} _line={{ height: 8 }} isLoaded={!isLoading}>
            {collection && (
              <Heading color={'text.900'}>{collection.title}</Heading>
            )}
          </Skeleton.Text>
          {/** description */}
          <Skeleton.Text lines={2} size={4} isLoaded={!isLoading}>
            {collection && (
              <Markdown
                color={'text.900'}
                value={collection.description}
                flatListProps={{
                  contentContainerStyle: { backgroundColor: 'white' },
                }}
                styles={{ ...(isMobile ? mdStylesMobile : mdStylesDesktop) }}
              />
            )}
          </Skeleton.Text>
        </VStack>
      </VStack>

      {/** live groups */}
      {(isLoading || (collection && collection.groups.length > 0)) && (
        <VStack space={4} px={sectionSpace} mb={8}>
          <Skeleton.Text
            lines={1}
            width={40}
            _line={{ height: 6 }}
            isLoaded={!isLoading}>
            {collection && (
              <Heading size={'md'} color={'text.900'}>
                Live groups
              </Heading>
            )}
          </Skeleton.Text>
          <Skeleton.Text lines={1} width={300} isLoaded={!isLoading}>
            {collection && (
              <Text fontSize={'xs'} color={'text.900'}>
                Drop into one of our daily, live groups to connect with others
                on shared challenges.
              </Text>
            )}
          </Skeleton.Text>
          <HStack
            style={{ gap: 8 }}
            flexWrap={'wrap'}
            alignItems={'stretch'}
            flexDirection={cardDirection}>
            {isLoading &&
              [1, 2, 3].map((i) => <Skeleton key={i} width={cardWidth} />)}
            {collection?.groups?.map((group) => (
              <Box width={isMobile ? '100%' : cardWidth} key={group.id}>
                <GroupCard
                  activity={group}
                  width={cardWidth}
                  onPress={async () => gotoGroup(group)}
                />
              </Box>
            ))}
          </HStack>
        </VStack>
      )}

      {/** content */}
      {(isLoading || (collection && collection.content.length > 0)) && (
        <VStack space={4} px={sectionSpace} mb={8}>
          <Skeleton.Text
            lines={1}
            width={40}
            _line={{ height: 6 }}
            isLoaded={!isLoading}>
            {collection && (
              <Heading size={'md'} color={'text.900'}>
                Quick learning
              </Heading>
            )}
          </Skeleton.Text>
          <Skeleton.Text lines={1} width={300} isLoaded={!isLoading}>
            {collection && (
              <Text fontSize={'xs'} color={'text.900'}>
                Discover quick, engaging, and fun content that boosts your
                mental health and well-being!
              </Text>
            )}
          </Skeleton.Text>
          <HStack
            style={{ gap: 8 }}
            flexWrap={'wrap'}
            alignItems={'stretch'}
            flexDirection={cardDirection}>
            {isLoading &&
              [1, 2, 3].map((i) => <Skeleton key={i} width={cardWidth} />)}
            {collection?.content?.map((content) => (
              <Box
                key={content.id}
                width={isMobile ? '100%' : cardWidth}
                mb={isMobile ? 0 : 2}>
                <ActivityCard
                  activity={content}
                  onPress={async () => gotoGroup(content)}
                />
              </Box>
            ))}
          </HStack>
        </VStack>
      )}

      {/** paths */}
      {(isLoading || (collection && collection.paths.length > 0)) && (
        <VStack space={4} px={sectionSpace} mb={isMobile ? 8 : 0}>
          <Skeleton.Text
            lines={1}
            width={40}
            _line={{ height: 6 }}
            isLoaded={!isLoading}>
            {collection && (
              <Heading size={'md'} color={'text.900'}>
                Voyage paths
              </Heading>
            )}
          </Skeleton.Text>
          <Skeleton.Text lines={1} width={300} isLoaded={!isLoading}>
            {collection && (
              <Text fontSize={'xs'} color={'text.900'}>
                Voyages offers 5-15 minute guided learning experiences based on
                scientifically backed methodologies to meet your struggles, in
                the moment.
              </Text>
            )}
          </Skeleton.Text>
          <HStack
            mb={6}
            style={{ gap: 8 }}
            flexWrap={'wrap'}
            alignItems={'stretch'}
            flexDirection={cardDirection}>
            {isLoading &&
              [1, 2, 3].map((i) => <Skeleton key={i} width={cardWidth} />)}
            {collection?.paths?.map((path) => (
              <Box width={isMobile ? '100%' : cardWidth} mb={2} key={path.id}>
                <PathCard path={path} width={cardWidth} />
              </Box>
            ))}
          </HStack>
        </VStack>
      )}
    </ScrollView>
  )
}

export default Collection
