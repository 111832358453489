import { Center, Flex, HStack, Spacer, VStack } from 'native-base'
import { useEffect, useState } from 'react'
import AttendeeRole from '../../../../domain/enums/AttendeeRole'
import {
  useDesktopBreakpoint,
  useMobileBreakpoint,
} from '../../../../domain/services/useBreakpoint'
import { useChat } from '../../../../domain/services/useChat'
import { PEOPLE } from '../../constants/roomConstants'
import useAnalytics from '../../hooks/useAnalytics'
import useIntercom from '../../hooks/useIntercom'
import useRoomToasts from '../../hooks/useRoomToasts'
import RoomLayout from '../../layouts/RoomLayout'
import useRoomClientContext from '../../providers/RoomClientProvider'
import useRoomContext from '../../providers/RoomProvider'
import LiveGroupModal from '../modals/LiveGroupModal.web'
import AudioFeed from './AudioFeed'
import Chat from './Chat'
import EndGroupModal from './room-desktop/EndGroupModal'
import RoomMobileActions from './room-mobile/RoomMobileActions'
import RoomMobileHeader from './room-mobile/RoomMobileHeader'
import RoomMobileTabs from './room-mobile/RoomMobileTabs'
import Roster from './Roster'
import ScreenShareAlert from './ScreenShareAlert'
import RoomSettings from './settings/RoomSettings'
import VideoFeed from './VideoFeed'

const Room = () => {
  /* State */
  const isDesktop = useDesktopBreakpoint()
  const isMobile = useMobileBreakpoint()
  const { update } = useIntercom()
  const analytics = useAnalytics()
  const [tabSelected, setTabSelected] = useState(PEOPLE)
  const room = useRoomContext()
  const roomClient = useRoomClientContext()
  const [messages, sendMessage] = useChat(room.roomId)
  const [otherUserMessageCount, setOtherUserMessageCount] = useState(0)
  const [currentAudio, setCurrentAudio] = useState({
    enabled: true,
    iMutedMyself: true,
  })

  const [presenter, setPresenter] = useState('')
  const [isScreenSharing, setIsScreenSharing] = useState(false)

  useEffect(() => {
    analytics.track('Live Group Room Reached')
  }, [])

  useEffect(() => {
    update({ hideDefaultLauncher: true })
  }, [])

  useEffect(() => {
    if (!isDesktop) {
      const presenterAlias = room.attendees.find((p) => p.screenOn)?.alias
      setPresenter(presenterAlias === undefined ? presenter : presenterAlias)
      setIsScreenSharing(presenterAlias !== undefined)
    }
  }, [room.me.screenOn, room.attendees])

  useEffect(() => {
    setCurrentAudio({ ...currentAudio, enabled: room?.me.microphoneOn })
  }, [room.me.microphoneOn])

  const toggleMute = () => {
    room.toggleMicrophone()
    const isAudioEnabled = currentAudio.enabled
    let iMutedMyself = false
    if (isAudioEnabled) {
      iMutedMyself = true
    }
    setCurrentAudio({ enabled: !isAudioEnabled, iMutedMyself })
  }

  const raisedHandCount =
    room.me.role === AttendeeRole.Moderator
      ? room.attendees.filter((a) => a.handRaised).length
      : 0
  /* Hooks */
  // Set otherUserMessageCount

  useEffect(() => {
    const otherUserMessages = messages.filter(
      (m) => m.user !== 'SYSTEM' && m.user !== room.me.userId
    )
    setOtherUserMessageCount(otherUserMessages.length)
  }, [messages])

  /* Render */
  const actions = [
    {
      handlePress: toggleMute,
      isActive: room.me.microphoneOn,
      isVisible: room.me.role !== AttendeeRole.Spectator,
      isMain: true,
      icon: 'microphone-slash',
      activeIcon: 'microphone',
      isSolid: true,
    },
    {
      handlePress: room.toggleCamera,
      isActive: room.me.cameraOn,
      isVisible: room.me.role === AttendeeRole.Moderator,
      isMain: true,
      icon: 'video-slash',
      activeIcon: 'video',
      moderatorOnly: true,
    },
    {
      handlePress: roomClient.shareScreen,
      isActive: room.me.screenOn,
      isVisible: room.me.role === AttendeeRole.Moderator,
      isMain: true,
      icon: 'display-slash',
      activeIcon: 'display',
      bg: 'muted.600',
      activeBg: 'secondary.500',
      activeColor: 'text.50',
    },
    {
      handlePress: room.toggleHandRaised,
      isActive: room.me.handRaised,
      isVisible:
        room.me.role === AttendeeRole.Participant ||
        room.me.role === AttendeeRole.Spectator,
      isMain: true,
      icon: 'hand',
      isSolid: true,
      bg: 'muted.600',
      activeBg: 'secondary.500',
      activeColor: 'text.50',
    },
    {
      handlePress: room.leave,
      isVisible:
        room.me.role === AttendeeRole.Participant ||
        room.me.role === AttendeeRole.Spectator,
      isMain: true,
      icon: 'arrow-right-from-bracket',
      bg: 'error.500',
    },
    {
      handlePress: roomClient.toggleRoster,
      isActive: roomClient.rosterOpen,
      isVisible: true,
      icon: 'user-group',
      badge: raisedHandCount,
    },
    {
      handlePress: roomClient.toggleChat,
      isActive: roomClient.chatOpen,
      isVisible: true,
      icon: 'comments',
      badge: Math.max(otherUserMessageCount - roomClient.lastReadIndex, 0),
    },
    {
      handlePress: roomClient.toggleSettings,
      isActive: roomClient.settingsOpen,
      isVisible: room.me.role !== AttendeeRole.Spectator,
      icon: 'ellipsis-vertical',
    },
  ]

  room.me.role !== AttendeeRole.Spectator &&
    useRoomToasts({
      isAudioEnabled: currentAudio.enabled,
      iMutedMyself: currentAudio.iMutedMyself,
      isScreenSharing: isScreenSharing,
      personSharing: presenter,
      isSpeaking: roomClient.isSpeaking,
    })

  const [isStartGroupModalOpen, setIsStartGroupModalOpen] = useState(true)
  const [isActionModalOpen, setIsActionModalOpen] = useState(false)

  useEffect(() => {
    if (room.active) {
      const timerToOpen = setTimeout(() => {
        setIsActionModalOpen(true)
      }, 500)
      const timerToClose = setTimeout(() => {
        setIsActionModalOpen(false)
      }, 3000)

      return () => {
        clearTimeout(timerToOpen)
        clearTimeout(timerToClose)
      }
    }
  }, [room.active])

  return (
    <>
      <LiveGroupModal
        groupInactive={!room.active}
        show={
          room.me.role === AttendeeRole.Moderator &&
          isStartGroupModalOpen &&
          !room.active
        }
        body="Lights, camera..."
        heading="Ready to start the group?"
      />
      <LiveGroupModal
        closeModal={() => setIsActionModalOpen(false)}
        show={
          room.me.role === AttendeeRole.Moderator &&
          isActionModalOpen &&
          room.active
        }
        body="Action!"
        heading="You're live!"
      />
      <AudioFeed />
      <RoomSettings />
      {room.me.role === AttendeeRole.Moderator && <EndGroupModal />}
      {!isDesktop ? (
        <VStack w="100%" h="100%">
          <RoomMobileHeader />
          <Center background={'black'}>
            <VideoFeed height={isMobile ? '240px' : '480px'} borderRadius="0" />
            <RoomMobileActions isOpen={true} actions={actions} />
          </Center>
          <Flex flex={1}>
            <RoomMobileTabs
              tabSelected={tabSelected}
              selectTab={setTabSelected}
            />
          </Flex>
        </VStack>
      ) : (
        <RoomLayout actions={actions}>
          <VStack h="100%" pt="32px" px="32px" pb="96px">
            <ScreenShareAlert />
            <Flex>
              <Center
                position="absolute"
                right="0"
                left={`${room.active ? '0' : '35%'}`}>
                <VideoFeed />
              </Center>
            </Flex>
            <HStack flex={1}>
              <Flex
                h="100%"
                zIndex="2"
                opacity={roomClient.rosterOpen ? '1' : '0'}>
                <Roster />
              </Flex>
              <Spacer />
              <Flex
                h="100%"
                zIndex="2"
                opacity={roomClient.chatOpen ? '1' : '0'}>
                <Chat
                  messages={messages}
                  sendMessage={sendMessage}
                  otherUserMessageCount={otherUserMessageCount}
                />
              </Flex>
            </HStack>
          </VStack>
        </RoomLayout>
      )}
    </>
  )
}

export default Room
