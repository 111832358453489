import { useBreakpointValue } from 'native-base'

import { useEffect, useRef } from 'react'
import DesktopRoom from '../../components/liveGroup/room30/DesktopRoom'
import MobileRoom from '../../components/liveGroup/room30/MobileRoom'
import secureScreen from '../../hooks/authentication/secureScreen'
import useIntercom from '../../hooks/useIntercom'
import { AudioStream } from '../../hooks/useMeeting'
import { useRoomContext } from '../../providers/Room30Provider'

const RoomScreen30 = ({ isLobbyRoom }) => {
  const { audio } = useRoomContext()
  const { update } = useIntercom()
  const breakpoint = useBreakpointValue({
    base: 'mobile',
    lg: 'browser',
  })

  const audioRefs = useRef<Record<string, AudioStream>>({})

  useEffect(() => {
    audio.forEach((stream) => {
      if (audioRefs.current[stream.id]) return

      audioRefs.current[stream.id] = stream

      stream.play()
    })

    return () => {
      Object.values(audioRefs.current).forEach((stream) => stream.stop())
      audioRefs.current = {}
    }
  }, [audio])

  useEffect(() => {
    update({ hideDefaultLauncher: true })
  }, [])

  return breakpoint === 'mobile' ? (
    <MobileRoom />
  ) : (
    <DesktopRoom isLobbyRoom={isLobbyRoom} />
  )
}

export default secureScreen(RoomScreen30)
