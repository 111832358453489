import { Box, StatusBar } from 'native-base'
import { Platform } from 'react-native'
import { useDesktopBreakpoint } from '../../../domain/services/useBreakpoint'
import Collections from '../components/collection/Collections'
import BackButton from '../components/navigation/BackButton'
import FixedContainer from '../components/_shared/layout/FixedContainer'
import DefaultLayout from '../layouts/DefaultLayout'

const CollectionsScreen = () => {
  const isDesktop = useDesktopBreakpoint()
  const mobileWeb = Platform.OS === 'web' && !isDesktop

  return (
    <DefaultLayout
      hasNavFooter={false}
      scrollView={isDesktop}
      activeLabel=""
      bg={isDesktop ? 'muted.50' : 'bgDefault'}>
      <StatusBar barStyle="dark-content" />
      {isDesktop ? (
        <FixedContainer size="md" width="1024px">
          <Collections />
        </FixedContainer>
      ) : (
        <>
          <Box
            px={6}
            mt={mobileWeb ? 4 : 12}
            mb={4}
            borderBottomColor="muted.200"
            borderBottomWidth={1}>
            <BackButton customText="" style={{ marginBottom: 12 }} />
          </Box>
          <Collections />
        </>
      )}
    </DefaultLayout>
  )
}
export default CollectionsScreen
