import { Box, HStack, ScrollView } from 'native-base'
import { ReactNode } from 'react'
import { Platform } from 'react-native'
import { useDesktopBreakpoint } from '../../../../../domain/services/useBreakpoint'
import BackButton from '../../navigation/BackButton'

interface CompassContainerProps {
  children: ReactNode
}

const CompassContainer = ({ children }: CompassContainerProps) => {
  const isDesktop = useDesktopBreakpoint()

  return (
    <>
      {!isDesktop ? (
        <HStack
          ml={6}
          width="100%"
          alignItems="flex-end"
          justifyContent="space-between"
          height={98}
          backgroundColor="white"
          borderBottomWidth={1}
          shadow={0.5}
          borderBottomColor="muted.50"
          pb={2}>
          <BackButton customText="" />
        </HStack>
      ) : (
        <Box>
          <BackButton customText="" />
        </Box>
      )}
      <ScrollView
        borderRadius={isDesktop ? '20px' : 0}
        shadow={isDesktop ? 4 : 0}
        my={isDesktop ? 2 : 0}
        backgroundColor="white"
        pt={isDesktop ? 10 : 6}
        p={isDesktop ? 10 : 4}
        showsVerticalScrollIndicator={false}>
        {children}
        {Platform.OS === 'ios' && <Box height="50px" />}
      </ScrollView>
    </>
  )
}

export default CompassContainer
