import { Box, Button, Checkbox, Text } from 'native-base'
import { useState } from 'react'
import { useDesktopBreakpoint } from '../../../../../domain/services/useBreakpoint'
import { useCompleteStepMutation } from '../../../queries/voyageQueries'

const fields = [
  {
    value: 'yes',
    text: 'Yes',
  },
  {
    value: 'maybe',
    text: 'A little',
  },
  {
    value: 'no',
    text: 'Nope',
  },
]

const CheckboxButton = ({ item, selected, onSelect }) => (
  <Box
    width="100%"
    p={3}
    my={item.value === 'maybe' ? 3 : 0}
    borderRadius="10px"
    borderColor={item.value === selected ? 'primary.900' : 'muted.200'}
    borderWidth={1}>
    <Checkbox
      borderRadius="full"
      isChecked={item.value === selected}
      onChange={() => onSelect(item.value)}
      _checked={{
        backgroundColor: 'secondary.700',
        borderColor: 'secondary.700',
      }}
      value={item.value}
      my={1}>
      <Text fontSize="16px" color="primary.900">
        {item.text}
      </Text>
    </Checkbox>
  </Box>
)

const StepCompletionForm = ({ stepId }) => {
  const completeStepMutation = useCompleteStepMutation()
  const isDesktop = useDesktopBreakpoint()
  const [value, setValue] = useState('')

  const completeStep = () => {
    completeStepMutation.mutation.mutate(stepId)
  }

  return (
    <Box width={!isDesktop ? '100%' : '342px'} alignSelf="center">
      <Box
        mt={4}
        mb={2}
        borderRadius="20px"
        p={4}
        borderWidth={1}
        borderColor="grey.200">
        <Text mb={4} fontSize="18px" fontWeight={700} color="primary.900">
          Was this helpful?
        </Text>
        {fields.map((item) => (
          <CheckboxButton
            item={item}
            selected={value}
            onSelect={setValue}
            key={item.value}
          />
        ))}
      </Box>
      <Button isDisabled={!value} onPress={completeStep}>
        <Text fontSize="16px" fontWeight={500} color="white">
          Finish
        </Text>
      </Button>
    </Box>
  )
}

export default StepCompletionForm
