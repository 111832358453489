import { Box, HStack, Text } from 'native-base'
import {
  ImageBackground,
  ImageSourcePropType,
  useWindowDimensions,
} from 'react-native'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import useGetOasisColors from '../../hooks/useGetOasisColors'
import BackButton from '../navigation/BackButton'

interface HeaderProps {
  source?: ImageSourcePropType
  backButtonTitle?: string
  title?: string
  backgroundColor?: string
  textColor?: string
  centerElement?: JSX.Element
  rightAction?: JSX.Element
  backButtonOnPress?: () => void
}

const Header = ({
  source,
  backButtonTitle = 'Back',
  title,
  backgroundColor,
  textColor = 'white',
  centerElement,
  rightAction,
  backButtonOnPress,
}: HeaderProps) => {
  const { width, height } = useWindowDimensions()
  const { tabColor } = useGetOasisColors()
  const isDesktop = useDesktopBreakpoint()
  const headerHeight = height / 7

  const HeaderContent = () => (
    <HStack
      pb={isDesktop ? 3 : 6}
      flex={1}
      px={isDesktop ? 4 : 6}
      flexDirection="row"
      alignItems="flex-end"
      justifyContent="space-between">
      <Box width={width / 3}>
        <BackButton
          onPress={backButtonOnPress}
          color={textColor}
          customText={backButtonTitle}
        />
      </Box>
      <Box alignItems="center" width={width / 3} ml={-4} zIndex={1}>
        {centerElement ?? (
          <Text fontSize="lg" color={textColor}>
            {title}
          </Text>
        )}
      </Box>
      <Box width={width / 3} mb={rightAction ? -2 : 0}>
        {rightAction ?? <></>}
      </Box>
    </HStack>
  )

  return source ? (
    <ImageBackground
      source={source}
      style={{ width: '100%', height: headerHeight }}>
      <HeaderContent />
    </ImageBackground>
  ) : (
    <Box height={headerHeight} backgroundColor={backgroundColor ?? tabColor}>
      <HeaderContent />
    </Box>
  )
}

export default Header
