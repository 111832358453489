import { Button, Text } from 'native-base'
import useActivityButtonDetails from '../../../hooks/useActivityButtonDetails'
import { useModal } from '../../../providers/ModalProvider'

const ActivityActionButtonAndModal = ({
  activity,
  handleClose: handleParentClose,
  buttonStyle = {},
  buttonHidden = false,
}) => {
  const { openModal } = useModal()
  const {
    buttonText,
    buttonAction,
    buttonVariant,
    buttonColor,
    invalidButton,
    buttonDisabled,
  } = useActivityButtonDetails(
    activity,
    () => {
      handleParentClose()
      openModal()
    },
    () => {},
    handleParentClose
  )

  return !invalidButton && !buttonHidden ? (
    <Button
      testID="activity-action-button"
      variant={buttonVariant}
      onPress={buttonAction}
      style={buttonStyle}
      bgColor={buttonColor}
      isDisabled={buttonDisabled}>
      <Text
        fontSize="md"
        fontWeight={500}
        color={buttonVariant === 'outline' ? 'primary.700' : 'white'}>
        {buttonText}
      </Text>
    </Button>
  ) : null
}

export default ActivityActionButtonAndModal
