import { useEffect, useState } from 'react'
import { LocalVideoTrack } from 'twilio-video'
import AttendeeRole from '../../../domain/enums/AttendeeRole'
import { NO_CAMERA } from '../../../domain/models/interfaces/camera'
import { NO_MICROPHONE } from '../../../domain/models/interfaces/microphone'
import Configuration from '../../../domain/services/ConfigurationService'
import { useCabanaApi } from '../providers/CabanaApiProvider'
import { useCamerasContext } from '../providers/CameraProvider'
import { useMicrophonesContext } from '../providers/MicrophoneProvider'
import { useUserProfileContext } from '../providers/UserProfileProvider'
import useMeeting, { AudioStream, VideoStream } from './useMeeting'
import useSpeakingDetection from './useSpeakingDetection'
import useWebSocket from './useWebsocket'

export type RoomInfo = {
  id: string
  title: string
  description: string
  startTime: Date
  canRejoin: boolean
  duration: string
  type: string
  moderator: string
}

export type Attendee = {
  id: string
  alias: string
  role: string
  photo: string
  camera: boolean
  screenShare: boolean
  microphone: boolean
  handRaised: boolean
  speaking: boolean
  typing: boolean
  token: string
  screen: string
  connected: boolean
  orbColor: string
  feedbackSubmitted: boolean
}

export type ChatMessage = {
  alias: string
  message: string
  timestamp: Date
}

export type Feedback = {
  thumbsUp: boolean
  overallRating: number
  technologyRating: number
  groupCohesionRating: number
  moderatorRating: number
  comments: string
}

export type UseRoomResult = UseRoomActions & {
  connected: boolean
  error: string | null

  info: RoomInfo
  me: Attendee
  participants: Attendee[]
  audio: AudioStream[]
  video: VideoStream[]
  messages: ChatMessage[]
  screenTrack: LocalVideoTrack | null
  showChat: boolean
  setShowChat: (show: boolean) => void
  showParticipants: boolean
  setShowParticipants: (show: boolean) => void
}

interface UseRoomActions {
  chooseAlias: (alias: string) => Promise<void>
  join: () => Promise<void>
  leave: () => Promise<void>
  start: () => Promise<void>
  stop: () => Promise<void>
  microphoneOn: () => Promise<void>
  microphoneOff: () => Promise<void>
  attendeeMicrophoneOff: (attendeeId: string) => Promise<void>
  cameraOn: () => Promise<void>
  cameraOff: () => Promise<void>
  screenShareOn: () => Promise<void>
  screenShareOff: () => Promise<void>
  handUp: () => Promise<void>
  handDown: () => Promise<void>
  startSpeaking: () => Promise<void>
  stopSpeaking: () => Promise<void>
  startTyping: () => Promise<void>
  stopTyping: () => Promise<void>
  sendMessage: (message: string) => Promise<void>
  submitFeedback: (feedback: any) => Promise<void>
  changeToParticipant: () => Promise<void>
  changeToSpectator: () => Promise<void>
}

const useRoom = (roomId: string, meetingOptions: any = {}): UseRoomResult => {
  const [roomInfo, setRoomInfo] = useState<RoomInfo>(null)
  const [me, setMe] = useState<Attendee>(null)
  const [participants, setParticipants] = useState<Attendee[]>([])
  const [messages, setMessages] = useState<ChatMessage[]>([])
  const [showChat, setShowChat] = useState(true)
  const [showParticipants, setShowParticipants] = useState(true)

  const {
    connected: socketConnected,
    error: socketError,
    connect,
    disconnect,
    subscribe,
  } = useWebSocket(Configuration.CABANA_SERVICE_WEBSOCKET_URL)
  const {
    connected: meetingConnected,
    error: meetingError,
    join,
    leave,
    setMicrophone,
    microphoneOff,
    microphoneOn,
    setCamera,
    cameraOff,
    cameraOn,
    screenShareOff,
    screenShareOn,
    audio,
    video,
    screenTrack,
  } = useMeeting(me?.token, meetingOptions)
  const { selectedMicrophone } = useMicrophonesContext()
  const { selectedCamera } = useCamerasContext()
  const { userProfile } = useUserProfileContext()
  const { RoomApiV2 } = useCabanaApi()
  const { isSpeaking } = useSpeakingDetection()

  const onRoomUpdate: (data: any) => void = (data) => {
    setRoomInfo({
      id: data.id,
      title: data.title,
      description: data.description,
      startTime: data.startTime,
      canRejoin: data.canRejoin,
      duration: data.duration,
      type: data.type,
      moderator: data.moderator,
    })
    setMe(data.attendees.find((attendee) => attendee.id === userProfile.id))
    setParticipants(data.attendees)
  }

  const onChatUpdate: (data: any) => void = (data) => {
    const message = data as ChatMessage
    setMessages((prev) => [...prev, message])
  }

  const noop = async () => {}
  const actions: UseRoomActions = me
    ? {
        join: () => RoomApiV2.join(roomId, me.id),
        leave: () => RoomApiV2.leave(roomId, me.id),
        start: () => RoomApiV2.start(roomId),
        stop: () => RoomApiV2.stop(roomId),
        chooseAlias: (alias: string) =>
          RoomApiV2.chooseAlias(roomId, me.id, alias),
        microphoneOn: () => RoomApiV2.unmute(roomId, me.id),
        microphoneOff: () => RoomApiV2.mute(roomId, me.id),
        attendeeMicrophoneOff: (attendeeId: string) =>
          RoomApiV2.mute(roomId, attendeeId),
        cameraOn: () => RoomApiV2.cameraOn(roomId, me.id),
        cameraOff: () => RoomApiV2.cameraOff(roomId, me.id),
        screenShareOn: () => RoomApiV2.screenShareOn(roomId, me.id),
        screenShareOff: () => RoomApiV2.screenShareOff(roomId, me.id),
        sendMessage: (message: string) => RoomApiV2.chat(roomId, message),
        handUp: () => RoomApiV2.handUp(roomId, me.id),
        handDown: () => RoomApiV2.handDown(roomId, me.id),
        startSpeaking: () => RoomApiV2.startSpeaking(roomId, me.id),
        stopSpeaking: () => RoomApiV2.stopSpeaking(roomId, me.id),
        startTyping: () => RoomApiV2.startTyping(roomId, me.id),
        stopTyping: () => RoomApiV2.stopTyping(roomId, me.id),
        changeToParticipant: () =>
          RoomApiV2.changeRole(roomId, me.id, 'PARTICIPANT'),
        changeToSpectator: () =>
          RoomApiV2.changeRole(roomId, me.id, 'SPECTATOR'),
        submitFeedback: (feedback: Feedback) =>
          RoomApiV2.feedback(roomId, me.id, feedback),
      }
    : {
        join: noop,
        leave: noop,
        start: noop,
        stop: noop,
        chooseAlias: noop,
        microphoneOn: noop,
        microphoneOff: noop,
        attendeeMicrophoneOff: noop,
        cameraOn: noop,
        cameraOff: noop,
        screenShareOn: noop,
        screenShareOff: noop,
        sendMessage: noop,
        handUp: noop,
        handDown: noop,
        startSpeaking: noop,
        stopSpeaking: noop,
        startTyping: noop,
        stopTyping: noop,
        changeToParticipant: noop,
        changeToSpectator: noop,
        submitFeedback: noop,
      }

  // connect to the websocket on mount
  useEffect(() => {
    if (userProfile.id) connect()

    // disconnect on unmount
    return () => {
      disconnect()
    }
  }, [userProfile.id])

  // (re-)subscribe to websocket topics on connect
  useEffect(() => {
    if (!socketConnected) return

    // subscribe to room updates
    const roomSubscription = subscribe(`/room/${roomId}`, onRoomUpdate)

    // get the chat history subscribe to chat updates
    RoomApiV2.chatHistory(roomId).then(setMessages)
    const chatSubscription = subscribe(`/room/${roomId}/chat`, onChatUpdate)

    // clean-up subscriptions
    return () => {
      roomSubscription?.unsubscribe()
      chatSubscription?.unsubscribe()
    }
  }, [socketConnected])

  // join meeting when in ROOM
  useEffect(() => {
    if (me?.screen === 'ROOM') {
      join()

      return () => {
        leave()
      }
    } else {
      leave()
    }
  }, [me?.screen])

  // set the meeting microphone
  useEffect(() => {
    if (!meetingConnected) return

    setMicrophone(selectedMicrophone).then(() => {
      // turn the microphone off, if necessary
      if (!me?.microphone) microphoneOff()
    })

    if (me?.role !== AttendeeRole.Moderator) {
      // selecting NO_MICROPHONE changes to spectator and turns microphone off
      if (selectedMicrophone === NO_MICROPHONE) {
        actions.changeToSpectator().then(() => actions.microphoneOff())
      }
      // selecting a microphone changes to participant
      else {
        me?.role !== AttendeeRole.Moderator && actions.changeToParticipant()
      }
    }
  }, [selectedMicrophone, meetingConnected])

  // turn meeting microphone off/on
  useEffect(() => {
    if (me?.microphone) microphoneOn()
    else microphoneOff()
  }, [me?.microphone])

  // set the meeting camera
  useEffect(() => {
    if (!meetingConnected) return

    setCamera(selectedCamera).then(() => {
      // turn the camera off, if necessary
      if (!me?.camera) cameraOff()
    })

    // selecting NO_CAMERA also turns the camera off
    if (selectedCamera === NO_CAMERA) actions.cameraOff()
  }, [selectedCamera, meetingConnected])

  // speaking detection
  useEffect(() => {
    if (!me) return

    // can only speak in the room
    if (me.screen === 'ROOM') {
      if (me.microphone && isSpeaking && !me.speaking) actions.startSpeaking()
      else if (me.microphone && !isSpeaking && me.speaking)
        actions.stopSpeaking()
    }
  }, [me, isSpeaking])

  // turn meeting camera off/on
  useEffect(() => {
    if (me?.camera) cameraOn()
    else cameraOff()
  }, [me?.camera])

  // screen share with the meeting
  useEffect(() => {
    if (me?.screenShare) screenShareOn()
    else screenShareOff()
  }, [me?.screenShare])

  return {
    connected: socketConnected && meetingConnected,
    error: socketError || meetingError,
    info: roomInfo,
    me,
    participants,
    audio,
    video,
    messages,
    screenTrack,
    showChat,
    setShowChat,
    showParticipants,
    setShowParticipants,
    ...actions,
  }
}

export default useRoom
