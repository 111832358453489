export const convertDatesToEpochSeconds = (obj: any): any => {
  if (obj === null || obj === undefined) return obj

  if (obj instanceof Date) {
    // Convert Date to epoch seconds
    return Math.floor(obj.getTime() / 1000)
  }

  if (Array.isArray(obj)) {
    // Process arrays recursively
    return obj.map((item) => convertDatesToEpochSeconds(item))
  }

  if (typeof obj === 'object' && obj !== null) {
    // Process objects recursively
    const result: Record<string, any> = {}
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        result[key] = convertDatesToEpochSeconds(obj[key])
      }
    }
    return result
  }

  // For primitive values, return as-is
  return obj
}
