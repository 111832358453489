import { createContext, useContext } from 'react'
import useRoom from '../hooks/useRoom'
import { RoomProviderProps, UseRoomContext } from './Room30Provider'

const RoomContext = createContext<UseRoomContext | undefined>(undefined)

export const useRoomContext = (): UseRoomContext => useContext(RoomContext)

export const RoomProvider: React.FC<RoomProviderProps> = ({
  children,
  roomId,
}) => {
  const context: UseRoomContext = useRoom(roomId)

  return <RoomContext.Provider value={context}>{children}</RoomContext.Provider>
}
