import { useEffect, useMemo, useState } from 'react'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import ActivityModalPreview from '../../components/explore/activities/ActivityModalPreview'
import GroupCalendarViewDesktop from '../../components/explore/liveGroups/GroupCalendarViewDesktop'
import GroupCalendarViewMobile from '../../components/explore/liveGroups/GroupCalendarViewMobile'
import { useCalendarView } from '../../components/explore/queries'
import secureScreen from '../../hooks/authentication/secureScreen'
import useAnalytics from '../../hooks/useAnalytics'

const getTitle = (date) => {
  const dateArray = date.toDateString().split(' ')
  const dayName = dateArray[0]
  const month = dateArray[1]
  let day = dateArray[2]
  if (day[0] === '0') {
    day = day[1]
  }
  const year = dateArray[3]
  return `${dayName}, ${month} ${day}`
}

const GroupCalendarScreen = () => {
  const isDesktop = useDesktopBreakpoint()
  const { groups, isLoading } = useCalendarView()
  const [modalLiveGroupData, setModalLiveGroupData] = useState(null)
  const [modalLiveGroupShown, setModalLiveGroupShown] = useState(false)
  const handleCloseModal = () => setModalLiveGroupShown(false)
  const analytics = useAnalytics()

  useEffect(() => {
    analytics.track('Live Groups Reached')
  }, [])

  const dateEvents = useMemo(() => {
    if (isLoading) return []
    let localDateEvents = []
    let lastDate = null
    for (let group of groups) {
      const newTitle = getTitle(group.startDate)
      const index = localDateEvents.findIndex((x) => x.title === newTitle)
      if (index === -1) {
        const newDate = new Date(group.startDate.toDateString())
        if (lastDate && lastDate.getDate() + 1 < newDate.getDate()) {
          const date1 = new Date(lastDate)
          date1.setDate(date1.getDate() + 1)
          const title1 = getTitle(date1)
          let combinedTitle = title1
          const date2 = new Date(newDate)
          date2.setDate(date2.getDate() - 1)
          if (date1 < date2) {
            const title2 = getTitle(date2)
            combinedTitle += ` - ${title2}`
          }
          localDateEvents.push({
            title: combinedTitle,
            data: [{ title: 'EMPTY' }],
            date: date2,
          })
        }

        localDateEvents.push({
          title: newTitle,
          data: [group],
          date: newDate,
        })

        lastDate = newDate
      } else {
        localDateEvents[index].data.push(group)
      }
    }
    let itemCount = 0
    const newDateEvents = localDateEvents.reduce((acc, curr) => {
      acc.push({
        title: curr.title,
        date: curr.date,
        type: 'title',
        index: itemCount,
      })
      itemCount++
      curr.data.map((item) => {
        acc.push({
          ...item,
          type: item.title === 'EMPTY' ? 'empty' : item.type,
          index: itemCount,
        })
        itemCount++
      })
      return acc
    }, [])
    return newDateEvents
  }, [groups, isLoading])

  const onLiveGroupPress = (group) => () => {
    setModalLiveGroupData(group)
    setModalLiveGroupShown(true)
  }

  if (!dateEvents || !dateEvents.length > 0) return null
  return (
    <>
      {!isDesktop ? (
        <GroupCalendarViewMobile
          dateEvents={dateEvents}
          onLiveGroupPress={onLiveGroupPress}
        />
      ) : (
        <GroupCalendarViewDesktop
          dateEvents={dateEvents}
          onLiveGroupPress={onLiveGroupPress}
        />
      )}
      <ActivityModalPreview
        modalData={modalLiveGroupData}
        modalShown={modalLiveGroupShown}
        handleCloseModal={handleCloseModal}
        bottomSheetStyling={{
          hasDivider: false,
          width: 400,
          top: isDesktop ? '0px' : null,
          hasCloseOnBottom: false,
        }}
      />
    </>
  )
}

export default secureScreen(GroupCalendarScreen)
