import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useGetProfile } from '../components/profile/queries'
import useAuthentication from '../hooks/authentication/useAuthentication'
import useCustomerIo from '../hooks/customerio/useCustomerIo'
import { NavigationParams } from '../screens/NavigationScreen'
import { useCabanaApi } from './CabanaApiProvider'

const UserProfileContext = createContext({
  selectGoal: null,
  selectMentalHealthMinutes: null,
  selectProfessionalRole: null,
  selectOasis: null,
  userProfile: null,
  successModalShown: null,
  closeSuccessModal: null,
  previousMinutes: null,
  intercomHmacValue: null,
  isFetched: null,
})

export const useUserProfileContext = () => useContext(UserProfileContext)

export const UserProfileProvider = ({ children }) => {
  const { MemberProfileApi } = useCabanaApi()
  const { user } = useAuthentication()
  const { userProfile, profileComplete, isFetched } = useGetProfile(!!user)
  const queryClient = useQueryClient()
  const [previousMinutes, setPreviousMinutes] = useState(-1)
  const [successModalShown, setSuccessModalShown] = useState(false)
  const didMount = useRef(false)
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const currentRoute = navigation.getCurrentRoute()
  const customerIo = useCustomerIo()

  const closeSuccessModal = useCallback(() => {
    setSuccessModalShown(false)
    setPreviousMinutes(userProfile.healthMinutesProgress)
  }, [
    setSuccessModalShown,
    userProfile.healthMinutesProgress,
    setPreviousMinutes,
  ])

  useEffect(() => {
    if (!previousMinutes || previousMinutes === -1) {
      setPreviousMinutes(userProfile.healthMinutesProgress)
    } else {
      const goal = userProfile.mentalHealthMinutes * 7
      if (
        previousMinutes != userProfile.healthMinutesProgress &&
        previousMinutes < goal &&
        userProfile.healthMinutesProgress >= goal
      ) {
        setSuccessModalShown(true)
      } else {
        setPreviousMinutes(userProfile.healthMinutesProgress)
      }
    }
  }, [
    userProfile.healthMinutesProgress,
    userProfile.mentalHealthMinutes,
    previousMinutes,
    setPreviousMinutes,
  ])

  useEffect(() => {
    if (isFetched) {
      if (userProfile.organization === null) {
        if (currentRoute?.path?.includes('?code')) {
          navigation.reset({
            index: 0,
            routes: [{ name: 'VerifyAccessCode', path: '/verify-account' }],
          })
        } else {
          navigation.reset({
            index: 0,
            routes: [{ name: 'VerifyEligibility', path: '/verify-account' }],
          })
        }
      } else if (!profileComplete) {
        navigation.reset({
          index: 0,
          routes: [{ name: 'Personalization', path: '/personalization' }],
        })
      }
    }
  }, [isFetched, profileComplete, userProfile.organization])

  const { mutate: selectOasis } = useMutation(
    (oasis) => MemberProfileApi.chooseOasis(oasis),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['Profile'], {
          ...userProfile,
          oasis: JSON.parse(data.config.data).oasis,
        })
      },
    }
  )

  const { mutate: selectGoal } = useMutation(
    ({ goal, isCustomGoal }) => MemberProfileApi.chooseGoal(goal, isCustomGoal),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['Profile'], {
          ...userProfile,
          goal: JSON.parse(data.config.data).goal,
          customGoal: JSON.parse(data.config.data).customGoal,
        })
      },
    }
  )

  const { mutate: selectMentalHealthMinutes } = useMutation(
    (minutes) => MemberProfileApi.chooseMentalHealthMinutes(minutes),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['Profile'], {
          ...userProfile,
          mentalHealthMinutes: Number.parseInt(
            JSON.parse(data.config.data).minutes
          ),
        })
      },
    }
  )

  const { mutate: selectProfessionalRole } = useMutation(
    (professionalRoles) =>
      MemberProfileApi.chooseProfessionalRoles(professionalRoles),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['Profile'], {
          ...userProfile,
          professionalRoles: JSON.parse(data.config.data).professionalRoles,
        })
      },
    }
  )

  useEffect(() => {
    if (didMount.current) queryClient.removeQueries('Profile')
    else didMount.current = true
  }, [user])

  useEffect(() => {
    if (userProfile.id) {
      customerIo.identify(userProfile.id, userProfile)
    }
  }, [userProfile])

  const context = useMemo(
    () => ({
      selectGoal,
      selectMentalHealthMinutes,
      selectProfessionalRole,
      selectOasis,
      userProfile,
      profileComplete,
      isFetched,
      successModalShown,
      closeSuccessModal,
      previousMinutes,
      intercomHmacValue: undefined,
    }),
    [isFetched]
  )

  return (
    <UserProfileContext.Provider value={context}>
      {children}
    </UserProfileContext.Provider>
  )
}
